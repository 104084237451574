
// Libraries
import * as React from 'react'
import classNames from 'classnames'

// Components
import { Link } from 'gatsby'

const HeaderLogos = ({ isAdmin, absolute }) => {
	const homeLink = isAdmin ? '/admin/dashboard' : '/'

	const classes = classNames('header-logos', { 'header-logos--absolute': absolute })

	return (
		<div className={classes}>
			<Link to={homeLink} className="header-logos__link">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 93.09" className="header-logos__cfc">
					<g fill="#FFF">
						<g>
							<g>
								<path id="Path_21" data-name="Path 21" className="cls-1"
									  d="M8.2,24.45A5,5,0,0,1,9,26.52v18.6h7.38v-19a11.71,11.71,0,0,0-9-10.41V7.39H202.86V18.93h7.38V0H0V22.62H3.69A5.12,5.12,0,0,1,8.2,24.45Z" />
								<path id="Path_22" data-name="Path 22" className="cls-1"
									  d="M202.86,85.72H7.38V77.44a11.7,11.7,0,0,0,9-10.41V48H9v18.6a5,5,0,0,1-.83,2.06,5.15,5.15,0,0,1-4.52,1.83H0V93.09H210.24V74.17h-7.38Z" />
								<path id="Path_23" data-name="Path 23" className="cls-1"
									  d="M45.67,33.46c0-4.2-2.8-7.28-9.41-7.28-9.23,0-11.79,6.46-11.79,11,0,4.35,2.61,8,9.41,8,6.56,0,9.89-2.82,11.07-7.36H37.79c-.74,2-1.84,2.77-3.17,2.77s-2.18-1-2.18-3.05.84-7,3.89-7c1.64,0,2.13,1.18,2,2.92Z" />
								<path id="Path_24" data-name="Path 24" className="cls-1"
									  d="M68.91,34.33c0-4-2.77-8.15-10.08-8.15C50.32,26.18,47,31.8,47,37.12c0,4.1,2.43,8,9.84,8C64.86,45.15,68.91,40.48,68.91,34.33ZM57.12,40.56c-1.75,0-2.18-1.51-2.18-3.08,0-2.27,1-6.92,3.79-6.92,1.64,0,2.26,1.16,2.26,2.85,0,2.46-.87,7.15-3.87,7.15Z" />
								<path id="Path_25" data-name="Path 25" className="cls-1"
									  d="M88.9,44.69l3.82-18H86.08L85.6,28.9a67.25,67.25,0,0,0-1.31,7.59h-.16c-.56-2.62-1.41-6.31-2.36-9.85H73.16l-3.82,18H76l.53-2.59a82.6,82.6,0,0,0,1.54-8.54h.13c.62,2.85,1.82,8.33,2.49,11.13Z" />
								<path id="Path_26" data-name="Path 26" className="cls-1"
									  d="M114.36,33.46c0-4.2-2.8-7.28-9.41-7.28-9.23,0-11.79,6.46-11.79,11,0,4.35,2.61,8,9.4,8,6.57,0,9.9-2.82,11.08-7.36h-7.15c-.74,2-1.85,2.77-3.18,2.77s-2.18-1-2.18-3.05.85-7,3.9-7c1.64,0,2.13,1.18,2,2.92Z" />
								<path id="Path_27" data-name="Path 27" className="cls-1"
									  d="M132.6,40h-9.87l.41-2h8.56l1-4.74h-8.56l.38-1.87h9l1-4.75h-16.3l-3.82,18h16.33Z" />
								<path id="Path_28" data-name="Path 28" className="cls-1"
									  d="M155.21,31.49c0-2.77-2.18-4.85-6.67-4.85h-11l-3.8,18h7.38l1.34-6.43h1.66c1.77,0,1.87.79,1.46,2.58a9.12,9.12,0,0,0-.3,3.85h7.15a13.63,13.63,0,0,1,.38-3.18c.69-3.2-.45-4.51-1.71-5.18A4.87,4.87,0,0,0,155.21,31.49Zm-9.82,2.73h-2.07l.66-3.15h2c1,0,1.54.49,1.54,1.38s-.7,1.78-2.08,1.78Z" />
								<path id="Path_29" data-name="Path 29" className="cls-1"
									  d="M166.54,44.69l2.82-13.3h5.17l1-4.75H157.62l-1,4.75h5.2L159,44.69Z" />
								<path id="Path_30" data-name="Path 30" className="cls-1"
									  d="M185.14,30.46c1.39,0,1.75.72,1.83,1.49h6.79c-.13-3.59-3.15-5.77-9.07-5.77-5.08,0-8.82,2.26-8.82,6.31,0,2.48,1.33,4.25,6.33,5.48,2.64.64,2.79,1.15,2.79,1.77,0,.79-.72,1.07-1.64,1.07-1.69,0-2.2-.89-2.26-1.66h-7c.41,3.87,3.46,6,9.33,6s9.76-2.24,9.76-6.36c0-2.77-1.72-4.54-6.89-5.77-1.77-.41-2.46-.79-2.46-1.48S184.4,30.46,185.14,30.46Z" />
								<path id="Path_31" data-name="Path 31" className="cls-1"
									  d="M39.81,52l.85-4.13H26.49L23.16,63.62h6.46l1.12-5.27H38l.87-4.14H31.61L32.1,52Z" />
								<path id="Path_32" data-name="Path 32" className="cls-1"
									  d="M49.07,64c7,0,10.55-4.06,10.55-9.43,0-3.53-2.41-7.11-8.78-7.11-7.42,0-10.35,4.9-10.35,9.55C40.49,60.59,42.61,64,49.07,64ZM50.75,51.3c1.43,0,2,1,2,2.48,0,2.15-.76,6.24-3.38,6.24-1.52,0-1.9-1.32-1.9-2.69,0-2,.87-6,3.31-6Z" />
								<path id="Path_33" data-name="Path 33" className="cls-1"
									  d="M78.71,52.1c0-2.41-1.9-4.22-5.81-4.22H63.31L60,63.61h6.44L67.6,58h1.46c1.54,0,1.63.69,1.27,2.26a7.92,7.92,0,0,0-.27,3.35H76.3a11.79,11.79,0,0,1,.33-2.77c.61-2.79-.4-3.93-1.49-4.51A4.24,4.24,0,0,0,78.71,52.1Zm-8.56,2.39H68.34l.58-2.75h1.7c.89,0,1.34.43,1.34,1.21s-.6,1.54-1.81,1.54Z" />
								<path id="Path_34" data-name="Path 34" className="cls-1"
									  d="M92.59,64c5.72,0,8.63-2.45,9.66-6.41H96C95.37,59.34,94.4,60,93.24,60s-1.9-.9-1.9-2.66.74-6.08,3.4-6.08c1.43,0,1.85,1,1.74,2.54h6.4c0-3.66-2.44-6.34-8.21-6.34-8,0-10.28,5.63-10.28,9.58C84.39,60.86,86.67,64,92.59,64Z" />
								<path id="Path_35" data-name="Path 35" className="cls-1"
									  d="M109.38,61.24h4.94l.2,2.37H121L118.7,47.88H110l-8.54,15.73h6.73ZM113.45,52h.16c.08,1.48.22,3.88.33,5.12h-2.59c.64-1.48,1.58-3.69,2.1-5.11Z" />
								<path id="Path_36" data-name="Path 36" className="cls-1"
									  d="M141.05,52.1c0-2.41-1.9-4.22-5.81-4.22h-9.59l-3.31,15.73h6.44L129.94,58h1.46c1.54,0,1.63.69,1.27,2.26a7.92,7.92,0,0,0-.27,3.35h6.24a11.79,11.79,0,0,1,.33-2.77c.61-2.79-.4-3.93-1.49-4.51A4.24,4.24,0,0,0,141.05,52.1Zm-8.56,2.39h-1.81l.58-2.75H133c.9,0,1.34.43,1.34,1.21s-.61,1.54-1.82,1.54Z" />
								<path id="Path_37" data-name="Path 37" className="cls-1"
									  d="M157.55,52l.87-4.13H144.2l-3.33,15.73h14.24l1.61-4.13h-8.6l.35-1.7h7.47l.89-4.14h-7.46L149.7,52Z" />
								<path id="Path_38" data-name="Path 38" className="cls-1"
									  d="M176.41,52.1c0-2.41-1.9-4.22-5.81-4.22H161L157.7,63.61h6.44L165.3,58h1.45c1.55,0,1.63.69,1.28,2.26a7.92,7.92,0,0,0-.27,3.35H174a11.79,11.79,0,0,1,.33-2.77c.61-2.79-.4-3.93-1.49-4.51A4.24,4.24,0,0,0,176.41,52.1Zm-8.56,2.39H166l.58-2.75h1.7c.89,0,1.34.43,1.34,1.21s-.6,1.54-1.81,1.54Z" />
								<path id="Path_39" data-name="Path 39" className="cls-1"
									  d="M186.07,51.21c1.21,0,1.52.62,1.59,1.29h5.93c-.11-3.13-2.75-5-7.92-5-4.42,0-7.68,2-7.68,5.5,0,2.17,1.16,3.71,5.52,4.79,2.3.56,2.43,1,2.43,1.54s-.62.94-1.43.94c-1.47,0-1.92-.78-2-1.45h-6.16c.36,3.37,3,5.24,8.14,5.24s8.52-2,8.52-5.55c0-2.41-1.5-3.95-6-5-1.54-.35-2.14-.68-2.14-1.29S185.43,51.21,186.07,51.21Z" />
								<circle id="Ellipse_1" data-name="Ellipse 1" className="cls-1" cx="206.55"
										cy="61.84" r="3.81" />
								<circle id="Ellipse_2" data-name="Ellipse 2" className="cls-1" cx="206.55"
										cy="51.75" r="3.81" />
								<circle id="Ellipse_3" data-name="Ellipse 3" className="cls-1" cx="206.55"
										cy="41.32" r="3.81" />
								<circle id="Ellipse_4" data-name="Ellipse 4" className="cls-1" cx="206.55"
										cy="31.22" r="3.81" />
							</g>
						</g>
					</g>
				</svg>
				<span className="sr-only">Concerts for Carers</span>
			</Link>
		</div>
	)
}

export default HeaderLogos