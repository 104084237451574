
// Libraries
import React from 'react'

// Components
import {Link} from 'gatsby'

export default function PrimaryNav() {
	const navItems = [
		{
			name: 'Events',
			to: '/events',
			active: true
		},
		{
			name: 'About',
			to: '/about',
			active: true
		},
		{
			name: 'Partners',
			to: '/our-partners',
			active: false
		},
		{
			name: 'Blog',
			to: '/blog',
			active: true
		},
		{
			name: 'FAQs',
			to: '/faqs',
			active: true
		},
		{
			name: 'Contact',
			to: '/contact',
			active: false
		}
	]

	return (
		<div className="primary-nav">
			<ul className="primary-nav__ul">
				{navItems.map((navItem) =>
					navItem.active && <li><Link to={navItem.to}>{navItem.name}</Link></li>
				)}
			</ul>
		</div>
	)
}
