import * as React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingBasket} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "gatsby";
import BasketContext from "../contexts/basket";

const BallotBasket = () => {
	return (
		<Link to="/basket" title="Ballot Basket">
			<BasketContext.Consumer>
				{value => (
					<>
						<FontAwesomeIcon icon={faShoppingBasket} title="Basket icon"/>
						<span className="basket--count">{value.itemCount}</span>
						<span className="sr-only"> Ballot Basket</span>
					</>
				)}
			</BasketContext.Consumer>
		</Link>
	)
}

export default BallotBasket