
// Libraries
import * as React from 'react'
import Classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import {useContext} from "react";
import NotificationContext from "../contexts/notification";

const Notification = (props) => {
	const { message, type, closing } = props

	let classes = Classnames([
		'notification',
		{
			'notification--success': type === 'success',
			'notification--error': type === 'error',
			'notification--closing': closing,
		}
	])

	const expireNotification = () => {
		expireNow()
	}

	const { expireNow } = useContext(NotificationContext)

	return (
		<div className={classes}>
			<p className="notification__message">{message}</p>
			<button onClick={expireNotification} className="notification__close"><FontAwesomeIcon icon={faTimes} /></button>
		</div>
	)
}

export default Notification
