// Libraries
import React, {useState, useEffect} from 'react'
import classNames from 'classnames'

// Services
import AuthService from '../services/auth'

// Components
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingBasket, faUser, faSignOut, faUserPlus, faSignIn, faTimes, faHeart} from '@fortawesome/pro-regular-svg-icons'
import {faFacebookF, faInstagram, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import BallotBasket from './ballotBasket'
import HeaderLogos from './headerLogos'

const authService = new AuthService()

// should the hamburger menu be its own component here? add class hamburger-open on dot click.
export default function SecondaryNav() {

	const [hamburgerOpen, setHamburgerOpen] = useState(false)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const isDocument = typeof document !== 'undefined'

	useEffect(() => {
		if(isDocument){
			if(hamburgerOpen){
				document.body.classList.add('menu-open')
			}
			else{
				setTimeout(() => {
					document.body.classList.remove('menu-open')
				}, 800)
			}
		}
	}, [hamburgerOpen])

	const navItems = [
		{
			name: 'Home',
			to: '/',
			active: true
		},
		{
			name: 'My Ballots',
			to: '/ballots',
			active: isLoggedIn && !isAdmin
		},
		{
			name: 'Dashboard',
			to: '/admin/dashboard',
			active: isLoggedIn && isAdmin
		},
		{
			name: 'Events',
			to: '/events',
			active: true
		},
		{
			name: 'About',
			to: '/about',
			active: true
		},
		{
			name: 'Partners',
			to: '/our-partners',
			active: true
		},
		{
			name: 'Blog',
			to: '/blog',
			active: true
		},
		{
			name: 'FAQs',
			to: '/faqs',
			active: true
		},
		{
			name: 'Contact',
			to: '/contact',
			active: true
		}
	]

	useEffect(() => {
		authService.isLoggedIn().then(response => {
			setIsLoggedIn(response)
		})

		authService.isAdmin().then(response => {
			setIsAdmin(response)
		})
	})

	const handleLogOut = () => {
		setHamburgerOpen(false);
		return authService.logOut()
	}

	return (
		<div className="secondary-nav">
			<ul className={classNames({'secondary-nav__list': true, 'secondary-nav__list--loggedin': isLoggedIn})}>
				{!isAdmin && <li className="donate-link">
					<Link to="/donate" title="Donate">
						<FontAwesomeIcon icon={faHeart} title="Donate icon"/>
						<span> Donate</span>
					</Link>
				</li>}
				{isLoggedIn
					? <>
						<li className="basket-link">
							<BallotBasket />
						</li>
						<li className="profile-link">
							<Link to={isAdmin ? "/admin/profile" : "/profile"}>
								<FontAwesomeIcon icon={faUser} title="User profile icon"/>
								<span className="sr-only"> Profile</span>
								{isAdmin && <span className="admin-ident">&nbsp;{localStorage.getItem('firstName')} - Admin</span>}
							</Link>
						</li>
					</>
					: <>
						<li className="register-link">
							<Link to="/register" title="Register">
								<FontAwesomeIcon icon={faUserPlus} title="Register icon"/>
								<span> Register</span>
							</Link>
						</li>
						<li className="login-link">
							<Link to="/login" title="Login">
								<FontAwesomeIcon icon={faSignIn} title="Login icon"/>
								<span> Login</span>
							</Link>
						</li>
					</>}
				<li className="dots">
					<button onClick={() => setHamburgerOpen(true)}>
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="sr-only">Open Menu</span>
					</button>

					<div className={`hamburger ${hamburgerOpen && 'hamburger--open'}`}>
						<HeaderLogos isAdmin={isAdmin} absolute />

						<button className="close" onClick={() => setHamburgerOpen(false)}>
							<FontAwesomeIcon icon={faTimes} title="Close icon" />
							<span className="sr-only">Close Menu</span>
						</button>

						<ul className="site-links">
							{navItems.map((navItem) =>
								navItem.active && <li>
									<Link to={navItem.to} onClick={() => {
										setTimeout(() => {
											setHamburgerOpen(false)
										}, 500)
									}}>
										<div className="dots dots--before">
											<span className="dot dot--4" />
											<span className="dot dot--3" />
											<span className="dot dot--2" />
											<span className="dot dot--1" />
										</div>
										{navItem.name}
										<div className="dots dots--after">
											<span className="dot dot--1" />
											<span className="dot dot--2" />
											<span className="dot dot--3" />
											<span className="dot dot--4" />
										</div>
									</Link>
								</li>
							)}
						</ul>

						<div className="login-links">
							<ul>
								<li className="donate-link">
									<Link to="/donate" title="Donate">
										<FontAwesomeIcon icon={faHeart} title="Donate icon"/>
										<span> Donate</span>
									</Link>
								</li>
								{isLoggedIn
									? <>
										{
											!isAdmin && <li>
												<Link to="/basket" title="Ballot Basket">
													<FontAwesomeIcon icon={faShoppingBasket} title="Basket icon"/>
													<span> Basket</span>
												</Link>
											</li>
										}
										<li>
											<Link to="/profile" title="Your Profile">
												<FontAwesomeIcon icon={faUser} title="User profile icon"/>
												<span> Profile</span>
											</Link>
										</li>
										<li>
											<button title="Logout" onClick={() => handleLogOut()} href='#'>
												<FontAwesomeIcon icon={faSignOut} title="Logout icon"/>
												<span> Logout</span>
											</button>
										</li>
									</>
									: <>
										<li className="register-link">
											<Link to="/register" title="Register">
												<FontAwesomeIcon icon={faUserPlus} title="Register icon"/>
												<span> Register</span>
											</Link>
										</li>
										<li className="login-link">
											<Link to="/login" title="Login">
												<FontAwesomeIcon icon={faSignIn} title="Login icon"/>
												<span> Login</span>
											</Link>
										</li>
									</>}
							</ul>
						</div>

						<div className="footer-icons">
							<a href="https://twitter.com/concerts_carers" target="_blank" rel="noreferrer"
							   className="footer-icons__link">
								<FontAwesomeIcon icon={faXTwitter} title="X (Twitter) logo"/>
								<span className="sr-only"> X (Twitter)</span>
							</a>
							<a href="https://www.facebook.com/concertsforcarers" target="_blank" rel="noreferrer"
							   className="footer-icons__link">
								<FontAwesomeIcon icon={faFacebookF} title="Facebook logo"/>
								<span className="sr-only"> Facebook</span>
							</a>
							<a href="https://www.instagram.com/concertsforcarers" target="_blank" rel="noreferrer"
							   className="footer-icons__link">
								<FontAwesomeIcon icon={faInstagram} title="Instagram logo"/>
								<span className="sr-only"> Instagram</span>
							</a>
						</div>

						<div className="footer-policy">
							<Link to="/cookie-policy" className="footer-policy__link">Cookie Policy</Link>
							<Link to="/terms" className="footer-policy__link">Terms &amp; Conditions </Link>
							<Link to="/privacy-policy" className="footer-policy__link">Privacy Policy</Link>
						</div>
					</div>
				</li>
			</ul>
		</div>
	)
}

