
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import PrimaryNav from './primaryNav'
import SecondaryNav from './secondaryNav'
import HeaderLogos from './headerLogos';

//Need to apply this class dynamically .header-scroll -how?

class Header extends React.Component {
	state = {
		hasScrolled: false
	}

	render() {
		const {hasScrolled} = this.state
		const {isAdmin, hideSideNav, pageAdminForm} = this.props
		let classes = Classnames([
			'header',
			{
				'header-scroll': hasScrolled,
				'header--admin': isAdmin,
				'header--admin-hidden-sidenav': hideSideNav,
				'header--admin-page-form': pageAdminForm,
			},
		])

		return <header className={classes}>
			<div className="container container--full-width">
				<HeaderLogos isAdmin={isAdmin} />
				<PrimaryNav/>
				<SecondaryNav/>
			</div>
		</header>
	}

	componentDidMount() {
		const isBrowser = typeof window !== "undefined"
		if (isBrowser) {
			window.addEventListener(
				"scroll",
				this.toggleBodyClass,
				{ passive: true }
			)
			this.toggleBodyClass()
		}
	}

	componentWillUnmount() {
		const isBrowser = typeof window !== "undefined"
		if (isBrowser) {
			window.removeEventListener(
				"scroll",
				this.toggleBodyClass,
				{ passive: true }
			)
		}
	}

	toggleBodyClass = () => {
		if (window.scrollY < 35) {
			this.setState({hasScrolled: false})
		} else {
			this.setState({hasScrolled: true})
		}
	}
}

export default Header
